import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import Section from "../components/shared/Section"
import Text from "../components/shared/Text"

const PrivacyPolicy = () => {
  const style = {
    textAlign: "left",
    margin: `0 auto`,
    maxWidth: `70%`,
  }

  const Wrapper = styled.div`
    ${style}
  `

  return (
    <Layout>
      <Section title="Privacy Policy">
        <Wrapper>
          <Text>
            This Privacy Policy governs the manner in which Meteor Affinity
            collects, uses, maintains and discloses information collected from
            users (each, a "User") of the{" "}
            <a href="https://www.meteoraffinity.com" title="Meteor Affinity">
              https://www.meteoraffinity.com
            </a>{" "}
            website ("Site"). This privacy policy applies to the Site and all
            products and services offered by Meteor Affinity.
          </Text>
          <Text type="subtitle">Personal identification information</Text>
          <Text>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to, when Users visit our
            site, fill out a form, and in connection with other activities,
            services, features or resources we make available on our Site. Users
            may be asked for, as appropriate, name, email address, phone number.
            Users may, however, visit our Site anonymously. We will collect
            personal identification information from Users only if they
            voluntarily submit such information to us. Users can always refuse
            to supply personal identification information, except that it may
            prevent them from engaging in certain Site related activities.
          </Text>
          <Text type="subtitle">Non-personal identification information</Text>
          <Text>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer and
            technical information about Users means of connection to our Site,
            such as the operating system and the Internet service providers
            utilized and other similar information.
          </Text>
          <Text type="subtitle">Web browser cookies</Text>
          <Text>
            Our Site may use "cookies" to enhance User experience. User's web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. Users may
            choose to set their web browser to refuse cookies, or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.
          </Text>
          <Text type="subtitle">How we use collected information</Text>
          <Text>
            Meteor Affinity collects and uses Users personal information for the
            following purposes:
            <ul>
              <li>
                <strong>To personalize user experience:</strong> We may use
                information in the aggregate to understand how our Users as a
                group use the services and resources provided on our Site.
              </li>
              <li>
                <strong>To improve our Site:</strong> We continually strive to
                improve our website offerings based on the information and
                feedback we receive from you.
              </li>
              <li>
                <strong>To improve customer service:</strong> Your information
                helps us to more effectively respond to your customer service
                requests and support needs.
              </li>
              <li>
                <strong>To send periodic emails:</strong> The email address
                Users provide will only be used to respond to their inquiries,
                and/or other requests or questions.
              </li>
            </ul>
          </Text>
          <Text type="subtitle">How we protect your information</Text>
          <Text>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information, username, password, transaction information and data
            stored on our Site.
          </Text>
          <Text type="subtitle">Sharing your personal information</Text>
          <Text>
            We do not sell, trade, or rent Users personal identification
            information to others. We may share generic aggregated demographic
            information not linked to any personal identification information
            regarding visitors and users with our business partners, trusted
            affiliates and advertisers for the purposes outlined above.
          </Text>
          <Text type="subtitle">Changes to this privacy policy</Text>
          <Text>
            Meteor Affinity has the discretion to update this privacy policy at
            any time. When we do, we will revise the updated date at the bottom
            of this page. We encourage Users to frequently check this page for
            any changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of modifications.
          </Text>
          <Text type="subtitle">Your acceptance of these terms</Text>
          <Text>
            By using this Site, you signify your acceptance of this policy. If
            you do not agree to this policy, please do not use our Site. Your
            continued use of the Site following the posting of changes to this
            policy will be deemed your acceptance of those changes.
          </Text>
          <Text type="subtitle">Contacting us</Text>
          <Text>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this site, please contact us at:
          </Text>
          <Text>
            Meteor Affinity, Inc.
            <br />
            <a href="https://www.meteoraffinity.com" title="Meteor Affinity">
              https://www.meteoraffinity.com
            </a>
          </Text>
          <Text>
            <Text isBold>Meteor Affinity, Inc.</Text>
            P.O Box 1162
            <br />
            Pineville, NC s28134
          </Text>
          <Text>
            <a href="mailto:info@meteoraffinity.com">info@meteoraffinity.com</a>
          </Text>
          <Text size="small">This document was last updated on May 2020.</Text>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default PrivacyPolicy
